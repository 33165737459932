import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 2000.000000 2000.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,2000.000000) scale(0.100000,-0.100000)">



<path d="M0 10000 l0 -10000 10000 0 10000 0 0 10000 0 10000 -10000 0 -10000
0 0 -10000z m11985 5332 c898 -171 1520 -874 1706 -1927 27 -152 37 -465 20
-616 -59 -512 -300 -964 -689 -1290 -269 -225 -611 -397 -1177 -589 -294 -100
-1417 -451 -1424 -445 -6 6 -509 1598 -506 1602 1 2 33 -8 72 -21 144 -49 281
-57 429 -27 258 53 481 236 584 479 13 29 25 51 27 49 2 -3 57 -174 122 -381
65 -207 121 -379 124 -383 9 -9 264 73 417 134 487 194 747 465 801 835 16
110 6 335 -21 463 -12 55 -43 167 -70 248 -110 331 -263 558 -469 696 -289
193 -677 201 -1239 26 -79 -25 -147 -47 -152 -50 -4 -3 39 -152 96 -332 56
-180 102 -328 101 -330 -1 -1 -27 14 -57 33 -80 49 -195 91 -302 110 -297 52
-624 -87 -791 -335 -24 -36 -38 -50 -41 -40 -28 75 -464 1484 -461 1487 6 6
833 266 1140 358 421 127 823 224 1070 259 154 22 546 14 690 -13z m-2496
-166 c-2 -2 -116 -39 -254 -81 l-250 -77 -44 16 c-24 9 -48 13 -54 9 -5 -3 -7
-2 -4 4 8 12 -88 47 -104 37 -7 -4 -9 -3 -6 3 4 7 -23 17 -71 28 -42 10 -93
22 -112 27 -19 5 -107 12 -195 16 -127 6 -59 8 335 12 272 3 555 6 629 8 74 1
132 0 130 -2z m-1259 -16 c0 -5 -9 -10 -21 -10 -11 0 -17 5 -14 10 3 6 13 10
21 10 8 0 14 -4 14 -10z m2460 -2059 c32 -62 -28 -114 -92 -80 -20 11 -19 13
23 54 24 24 47 44 51 44 4 1 12 -8 18 -18z m-90 -211 c5 -34 -1 -50 -25 -66
-21 -14 -31 -15 -50 -6 l-25 12 43 43 c41 43 53 47 57 17z m-823 -56 l42 -7
-14 -27 c-14 -27 -33 -40 -61 -40 -23 0 -54 38 -54 66 0 23 3 25 23 20 12 -3
41 -9 64 -12z m171 -131 c47 -6 49 -9 25 -35 -32 -35 -103 -12 -103 35 0 14 4
17 18 12 9 -4 36 -10 60 -12z m562 -23 c0 -11 -9 -29 -20 -40 -17 -17 -60 -28
-60 -16 0 7 64 73 73 75 4 0 7 -8 7 -19z m-386 -119 c34 -5 37 -8 23 -19 -23
-20 -67 -11 -86 18 -15 23 -15 24 4 16 11 -5 38 -12 59 -15z m-275 -1130 c11
-36 21 -69 21 -73 0 -4 -34 -8 -75 -8 -43 0 -75 4 -75 10 0 6 4 10 9 10 5 0
13 10 17 22 9 28 29 49 39 40 5 -4 5 -2 2 5 -8 13 25 76 34 65 4 -4 16 -36 28
-71z m-2904 18 c-6 -9 80 -140 87 -133 4 3 5 -6 4 -20 -2 -14 0 -24 4 -23 4 1
36 -35 72 -80 36 -46 69 -79 73 -75 5 4 5 2 2 -4 -11 -20 289 -304 322 -304 5
0 11 -4 13 -8 9 -25 305 -189 324 -180 15 8 21 -31 11 -70 l-11 -42 -628 0
c-345 0 -628 4 -628 8 0 5 74 222 165 482 91 260 165 475 165 477 0 2 6 -2 14
-9 8 -7 12 -15 11 -19z m3202 -1485 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-1747 -229 c11 -13 10 -14 -4 -9 -9 3 -16 10 -16 15 0 13 6 11
20 -6z m-4820 -3270 c457 -32 593 -44 600 -55 4 -6 80 -354 169 -773 90 -419
171 -799 181 -844 l18 -83 -288 0 -287 0 -39 198 -40 197 -372 3 -372 2 -100
-200 -100 -200 -305 0 c-168 0 -305 3 -305 6 0 16 872 1740 885 1750 8 6 11
14 7 18 -4 5 22 5 58 2 36 -3 166 -13 290 -21z m1620 -116 c91 -5 190 -12 220
-14 44 -3 55 -7 57 -22 2 -10 -35 -259 -80 -553 -46 -294 -82 -538 -80 -542 2
-5 159 -8 349 -8 l345 0 -7 -47 c-4 -27 -21 -138 -38 -248 l-31 -200 -633 -3
c-347 -1 -632 -1 -632 1 0 7 241 1533 251 1595 l11 63 52 -5 c28 -4 125 -11
216 -17z m1810 -129 c172 -10 215 -14 242 -19 16 -3 -30 -114 -166 -399 -58
-123 -106 -225 -106 -227 0 -3 101 -5 225 -5 208 0 225 1 225 18 0 9 -25 141
-55 292 -55 275 -61 320 -47 320 43 -1 526 -41 529 -43 2 -2 71 -319 153 -703
82 -384 152 -709 155 -721 l5 -23 -288 0 -288 0 -39 200 -39 200 -373 0 -373
0 -100 -200 -100 -200 -306 0 c-180 0 -304 4 -302 9 2 5 174 349 383 765 369
733 381 755 410 758 17 2 37 0 45 -4 8 -4 103 -12 210 -18z m1497 -107 c-3 -5
41 -8 97 -9 173 -2 529 -33 538 -47 6 -9 8 -9 8 1 0 23 98 0 120 -27 3 -4 18
-12 35 -19 60 -27 237 -137 290 -181 77 -64 128 -136 156 -222 21 -64 24 -93
24 -219 0 -133 -2 -152 -29 -234 -70 -212 -236 -374 -456 -444 -123 -39 -181
-46 -360 -46 -161 0 -182 2 -263 27 -161 50 -288 126 -405 244 l-73 74 18 28
c10 15 74 109 143 208 l125 181 73 -70 c139 -132 267 -190 422 -192 107 -1
148 14 199 69 106 115 41 237 -191 355 -260 134 -382 233 -443 361 -37 80 -57
169 -36 169 7 0 10 -3 8 -7z m1978 -139 c138 -9 251 -17 253 -19 2 -1 -22
-169 -52 -371 -31 -203 -56 -378 -56 -390 0 -19 7 -22 88 -28 144 -11 286 14
411 70 56 26 91 51 150 111 122 123 168 237 178 443 l6 115 31 -1 c180 -9 526
-40 526 -46 0 -5 -6 -6 -12 -2 -10 6 -10 4 0 -7 31 -33 3 -277 -49 -434 -47
-141 -111 -245 -224 -364 -188 -198 -380 -282 -740 -322 -55 -6 -304 -13 -554
-16 -439 -5 -453 -5 -448 13 3 11 49 301 103 645 94 601 98 625 118 622 12 -1
134 -10 271 -19z m2260 -159 c99 -8 205 -15 235 -15 91 -1 92 -5 37 -115 -26
-52 -47 -100 -47 -105 0 -6 84 -10 225 -10 l225 0 0 23 c-1 12 -7 51 -15 87
-21 96 -24 91 53 85 37 -3 159 -12 271 -20 113 -9 206 -17 208 -18 1 -2 48
-215 103 -473 56 -258 105 -486 109 -506 l9 -38 -288 0 -288 0 -38 200 -38
200 -373 0 -373 0 -100 -200 -100 -200 -305 0 c-168 0 -305 2 -305 5 0 7 150
308 372 743 178 350 191 372 216 372 15 0 108 -7 207 -15z m1963 -141 c-10
-10 -21 -13 -29 -8 -12 7 0 14 41 23 2 1 -3 -6 -12 -15z m295 -19 c109 -8 203
-13 209 -12 6 1 13 -4 15 -10 3 -7 0 -13 -6 -13 -6 0 -8 -6 -5 -14 4 -11 -114
-799 -131 -873 l-5 -23 -280 0 c-154 0 -280 2 -280 5 0 2 34 213 75 469 41
256 75 467 75 470 0 3 6 3 14 0 8 -3 17 -1 21 5 8 14 60 14 298 -4z m1202 -85
c99 -7 207 -15 241 -19 33 -4 73 -4 87 0 15 4 25 4 23 0 -3 -4 113 -15 257
-25 144 -9 309 -21 367 -26 58 -5 154 -12 213 -16 100 -6 108 -8 104 -25 -3
-11 -2 -17 2 -15 6 4 162 -700 161 -726 0 -5 -128 -8 -285 -8 l-284 0 -7 38
c-4 20 -21 109 -38 197 l-31 160 -375 3 -375 2 -100 -200 -100 -200 -302 0
c-167 0 -303 2 -303 5 0 3 99 203 220 444 l219 438 63 -8 c35 -4 144 -13 243
-19z m-5665 -2004 c0 -463 8 -417 -72 -432 -31 -5 -38 -3 -38 10 0 20 -17 20
-63 1 -53 -22 -148 -19 -202 6 -89 42 -139 132 -139 249 1 61 6 85 27 126 29
57 80 106 135 130 39 18 148 24 194 11 l27 -7 3 147 3 147 60 12 c33 6 61 12
63 13 1 0 2 -185 2 -413z m-1750 -6 l0 -410 -70 0 -70 0 0 400 0 400 23 4 c12
3 31 7 42 9 11 3 32 5 48 6 l27 1 0 -410z m2690 185 l0 -84 68 -3 67 -3 3 -52
c3 -49 1 -53 -22 -59 -14 -3 -46 -4 -71 -2 l-45 3 0 -131 c0 -162 5 -187 44
-229 l32 -33 -36 -6 c-154 -25 -179 10 -180 261 l0 132 -37 3 -38 3 -3 58 -3
57 41 0 40 0 0 74 0 74 48 10 c96 20 92 23 92 -73z m-5658 -225 c32 -90 59
-165 61 -167 2 -1 32 67 67 152 l64 155 53 0 c29 0 53 -3 53 -7 0 -22 -246
-533 -254 -530 -6 2 -38 73 -72 158 l-62 154 -26 -60 c-76 -174 -112 -249
-121 -252 -8 -2 -80 160 -220 500 l-16 38 68 -3 68 -3 55 -147 c30 -80 57
-149 60 -151 3 -3 36 71 74 164 38 93 74 168 80 166 6 -2 37 -77 68 -167z
m784 10 c30 -85 57 -162 60 -170 3 -8 34 54 71 143 l66 157 54 0 c35 0 53 -4
51 -11 -17 -52 -245 -528 -253 -529 -5 0 -37 68 -69 150 -33 83 -62 150 -66
150 -3 0 -35 -64 -70 -142 -64 -145 -75 -166 -85 -156 -6 6 -225 526 -225 534
0 3 30 4 67 2 l67 -3 56 -154 c31 -85 59 -150 62 -145 3 5 22 50 43 99 20 50
50 123 67 163 17 40 35 71 40 70 6 -2 35 -73 64 -158z m782 18 c27 -79 53
-153 56 -165 4 -13 11 -23 15 -23 3 0 34 66 68 148 l61 147 56 3 c31 2 56 2
56 0 0 -14 -249 -533 -256 -535 -6 -2 -32 50 -57 114 -26 65 -55 135 -65 156
l-17 38 -69 -156 c-38 -86 -73 -154 -79 -152 -8 2 -227 512 -227 529 0 5 29 8
64 8 l64 0 31 -80 c17 -44 42 -114 57 -156 32 -90 23 -99 109 111 65 158 63
155 74 155 5 0 31 -64 59 -142z m966 109 c19 -7 54 -10 89 -7 l57 5 0 -180 c1
-99 5 -211 10 -250 10 -79 9 -80 -58 -90 -34 -5 -46 -3 -56 10 -10 14 -15 14
-55 0 -56 -19 -151 -19 -199 1 -47 19 -97 67 -125 119 -30 54 -31 202 -3 255
38 72 111 133 181 151 36 10 117 2 159 -14z m881 7 c33 -8 87 -14 120 -13 l60
3 2 -40 c9 -152 7 -464 -3 -468 -6 -2 -32 -8 -58 -11 -39 -6 -46 -5 -46 9 0
20 -6 20 -60 1 -91 -32 -198 -15 -261 42 -80 72 -108 183 -75 296 23 78 92
152 166 177 68 24 80 24 155 4z m596 0 c30 -9 59 -21 65 -27 14 -16 6 -120
-10 -113 -137 61 -174 66 -199 25 -18 -29 0 -47 90 -93 48 -24 100 -56 115
-71 69 -69 36 -202 -59 -241 -44 -19 -154 -18 -225 2 l-58 17 0 58 c0 33 3 59
7 59 3 0 40 -12 81 -26 89 -30 137 -27 154 11 15 34 0 50 -97 94 -50 24 -92
51 -112 75 -29 32 -33 44 -33 91 0 49 4 59 35 93 55 61 140 77 246 46z m1157
0 c38 -10 88 -15 123 -12 l59 4 0 -215 c0 -119 3 -232 6 -252 7 -41 4 -43 -70
-54 -39 -6 -46 -5 -46 9 0 20 -6 20 -60 1 -128 -45 -265 7 -321 123 -35 70
-33 188 4 258 32 64 97 120 157 139 59 18 72 18 148 -1z m1046 -7 c19 -7 54
-10 89 -7 l57 5 0 -180 c1 -99 5 -211 10 -250 10 -79 9 -80 -58 -90 -34 -5
-46 -3 -56 10 -10 14 -15 14 -55 0 -56 -19 -151 -19 -199 1 -47 19 -97 67
-125 119 -30 54 -31 202 -3 255 38 72 111 133 181 151 36 10 117 2 159 -14z
m1021 0 c38 -13 40 -16 37 -53 -6 -75 -5 -74 -75 -52 -141 44 -251 -21 -251
-147 0 -67 27 -114 84 -142 57 -30 119 -29 192 1 l57 23 -6 -51 c-3 -28 -10
-56 -15 -63 -17 -21 -101 -43 -169 -43 -121 0 -208 49 -254 145 -35 71 -37
173 -5 240 28 58 106 127 167 148 56 20 174 17 238 -6z m484 7 c116 -34 189
-153 179 -289 -7 -76 -34 -131 -92 -182 -57 -50 -115 -67 -204 -61 -76 6 -117
23 -165 72 -50 50 -71 101 -75 187 -5 97 20 162 86 220 74 65 169 84 271 53z
m674 -8 c18 -7 45 -28 61 -46 l27 -33 32 31 c90 87 238 82 292 -9 18 -31 20
-55 23 -241 l3 -208 -65 0 -65 0 -3 180 c-3 164 -5 181 -23 199 -37 36 -116
32 -156 -8 l-29 -29 0 -171 0 -171 -70 0 -70 0 0 176 c0 96 -4 184 -10 194
-24 46 -121 43 -169 -5 -26 -25 -26 -28 -29 -195 l-4 -170 -64 0 -64 0 0 255
0 255 60 0 60 0 0 -41 0 -41 33 28 c71 60 161 79 230 50z m-6374 -402 c12 -15
21 -34 21 -44 0 -10 -9 -29 -21 -44 -41 -52 -122 -23 -122 44 0 67 81 96 122
44z m4629 10 c27 -19 30 -78 5 -106 -23 -25 -77 -24 -103 4 -58 63 26 152 98
102z"/>
<path d="M3650 6223 c-33 -105 -69 -190 -189 -440 -55 -116 -101 -214 -101
-217 0 -3 101 -6 225 -6 178 0 225 3 225 13 0 7 -24 135 -54 284 -30 149 -58
310 -62 357 -4 47 -10 86 -14 86 -3 0 -17 -35 -30 -77z"/>
<path d="M10254 3556 c-63 -28 -99 -103 -92 -195 8 -107 77 -159 176 -132 73
19 72 17 72 176 l0 143 -31 11 c-42 14 -87 13 -125 -3z"/>
<path d="M8212 3553 c-42 -20 -68 -57 -83 -116 -37 -150 67 -255 201 -201 l40
16 0 145 0 144 -35 15 c-44 18 -82 18 -123 -3z"/>
<path d="M9124 3550 c-57 -28 -86 -84 -87 -164 -1 -129 84 -195 198 -151 l45
17 0 144 0 145 -35 15 c-46 19 -72 18 -121 -6z"/>
<path d="M10885 3556 c-66 -29 -95 -84 -95 -179 0 -75 24 -122 75 -143 46 -19
81 -17 133 6 l42 19 0 139 0 140 -40 16 c-48 19 -75 20 -115 2z"/>
<path d="M11892 3553 c-42 -20 -68 -57 -83 -116 -37 -150 67 -255 201 -201
l40 16 0 145 0 144 -35 15 c-44 18 -82 18 -123 -3z"/>
<path d="M13394 3550 c-52 -21 -91 -111 -80 -189 21 -150 185 -189 252 -59 22
43 22 134 -1 182 -30 64 -105 93 -171 66z"/>
</g>
</svg>


		</Box >
	);

	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
